<template>
    <div class="mr-2 m-nav-left" v-if="menus.length">
        <v-list class="py-0">
            <v-list-tile
                    v-for="item in menus"
                    :title="item.title"
                    :id="item.stepId"
                    :to="item.path"
                    :key="item.text">
                <v-list-tile-content >
                    <v-list-tile-title   class="font-weight-medium fs-3">{{ item.text }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
    </div>
</template>

<script>
    import { mapState , mapMutations } from 'vuex'
    export default {
        name: "SideMenu",
        props:{
            menus : {
                type : Array
            }
        },
        computed: {
            ...mapState(['drawer' ,'mini']),
            showDrawer:{
                get() {
                    return this.drawer
                },
                set (drawer) {
                    this.changeRootState({drawer})
                }
            },
        },
        methods:{
            changeMini(){
                this.changeRootState({
                    mini:!this.mini
                })
            },
            ...mapMutations(['changeRootState'])
        },
        created(){
            let name = this.$vuetify.breakpoint.name
            let payload = name === 'md'?{drawer: false, mini: true}:{mini:['xs','sm'].indexOf(name)!==-1?false:this.mini}
            this.changeRootState(payload)
        }
    }
</script>

<style lang="scss">
    .m-nav-left{
        .v-list__tile.v-list__tile--link{
            &:hover{
                background-color: #d4dbff;
            }
        }
        .v-list__tile--active{
            background-color: #d4dbff;
            &:before{
                display: block;
                content:"";
                position: absolute;
                height: 100%;
                width: 4px;
                left: 0;
                background-color: #3f51b5;
            }
        }
    }
</style>
