<template>
    <v-container  class="hor-layout" grid-list-lg fluid>
        <v-layout column>
            <v-flex  xs12 >
                <slot name="title"></slot>
            </v-flex>
            <v-flex  xs12 class="hidden-sm-and-up">
                <slot name="nav"></slot>
            </v-flex>
            <v-layout d-flex >
                <slot name="mask">
                </slot>
                <v-flex  sm2 class="hidden-xs-only" >
                    <slot name="left"></slot>
                </v-flex>
                <v-flex  sm10 xs12>
                    <slot name="right"></slot>
                </v-flex>
            </v-layout>
        </v-layout>
        <slot></slot>
    </v-container>
</template>

<script>
    export default {
        name: "HorLayout"
    }
</script>

<style lang="scss">
    .hor-layout{

    }

</style>
