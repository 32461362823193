var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menus.length
    ? _c(
        "div",
        { staticClass: "mr-2 m-nav-left" },
        [
          _c(
            "v-list",
            { staticClass: "py-0" },
            _vm._l(_vm.menus, function(item) {
              return _c(
                "v-list-tile",
                {
                  key: item.text,
                  attrs: { title: item.title, id: item.stepId, to: item.path }
                },
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        { staticClass: "font-weight-medium fs-3" },
                        [_vm._v(_vm._s(item.text))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }