var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-route" },
    [
      _c(
        "v-bottom-sheet",
        {
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c("v-icon", { attrs: { outline: "" } }, [_vm._v("menu")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.sheet,
            callback: function($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet"
          }
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.menus, function(item, i) {
              return _c(
                "v-list-tile",
                { key: i, attrs: { to: item.path } },
                [_c("v-list-tile-title", [_vm._v(_vm._s(item.text))])],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }