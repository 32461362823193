var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "hor-layout", attrs: { "grid-list-lg": "", fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [_vm._t("title")], 2),
          _c(
            "v-flex",
            { staticClass: "hidden-sm-and-up", attrs: { xs12: "" } },
            [_vm._t("nav")],
            2
          ),
          _c(
            "v-layout",
            { attrs: { "d-flex": "" } },
            [
              _vm._t("mask"),
              _c(
                "v-flex",
                { staticClass: "hidden-xs-only", attrs: { sm2: "" } },
                [_vm._t("left")],
                2
              ),
              _c(
                "v-flex",
                { attrs: { sm10: "", xs12: "" } },
                [_vm._t("right")],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }