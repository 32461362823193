<template>
    <div class="side-route">
        <!--<v-menu bottom left>-->
            <!--<template v-slot:activator="{ on }">-->
                <!--<v-btn-->
                        <!--icon-->
                        <!--v-on="on">-->
                    <!--<v-icon outline >menu</v-icon>-->
                <!--</v-btn>-->
            <!--</template>-->
            <!--<v-list>-->
                <!--<v-list-tile-->
                        <!--:to="item.path"-->
                        <!--v-for="(item, i) in menus"-->
                        <!--:key="i">-->
                    <!--<v-list-tile-title>{{ item.text }}</v-list-tile-title>-->
                <!--</v-list-tile>-->
            <!--</v-list>-->
        <!--</v-menu>-->


        <v-bottom-sheet v-model="sheet">
            <template v-slot:activator>
                <v-btn icon>
                    <v-icon outline >menu</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-tile
                        :to="item.path"
                        v-for="(item, i) in menus"
                        :key="i">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>
    export default {
        name: "SideRoute",
        props: ['menus'],
        data (){
            return {
                sheet: false
            }
        }
    }
</script>

<style lang="scss">
    .side-route{}
</style>